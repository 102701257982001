/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";

//@import "../node_modules/primeicons/primeicons.css"
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
//@import "assets/theme/theme-blue.css";


$title-color: #2196F3;
//$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$fontFamily2:Lato, Helvetica, sans-serif !default;
$fontFamily:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$fontFamily3:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   champ custom styles
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

.champ-page-title{
    font-size: x-large; 
    font-weight: 600; 
    font-family: cursive;
    padding-top: 1rem ;
}

.champ-grid {
    margin-left: 5px; 
    margin-top: 5px; 
    margin-bottom: 5px; 
    line-height: 20px; 
    width: 100%; 
    height: 35px; 
    min-height: 25px;
}

.champ-formgroup-inline {
    /* display: contents !important; */
    align-items: center !important;
    line-height: 20px;
    padding-left: 5px;
}

.champ-button-link {
    box-shadow: none !important;
}

// .champ-button {
//     color:blue;
// }

.champ-date-picker {
    height: 2.15rem !important;
    width: 7rem !important;
    // border: 1px solid #ced4da;
    // border-radius: 3px;
    // box-sizing:border-box;
    // outline-color: #1f95f3;
    // font-weight: 700;
    // font-size: 11px;
    // padding-left: 5px;
}

.champ-form-title-row {
    font-size: 1em; 
    font-weight: bold;
    //margin-bottom: 10vh;
    background-color:white;
    //height: 30px;
    padding: 0px !important;
    margin: 0px !important;
    align-items: baseline;

}

.champ-card-sm {
    margin:0em;
    padding: 0em;
    // padding-top:0px;
    // padding-left:0px;
    // padding-right:0px;
    background-color: whitesmoke;
}


.champ-card-header-sm {
    margin:0em;
    //padding: 0em;
    padding-top:.2em;
    padding-bottom: .2em;
    padding-left:.5em;
    padding-right:.2em;
    background-color: rgb(55, 130, 173);
    color: white;
    font-weight: bold;
}

.champ-card-body-sm {
    margin:.5em;
    padding: 0em;
    background-color:whitesmoke;
}
.champ-notification-loading {
    //float: left;
    font-size: 15px;
    color:yellow;
    background: white;
    padding-left: 10px;
    //display: block;
    //position: relative;
    width: 150px;
    height: 25px;
    background-color:crimson;
    animation: blinker 2s linear infinite;
    margin-left: 10px;
    border-radius: 3px;
  }

  @keyframes blinker {
    50% { background-color: #2196F3; }
  }

.champ-accordion {
    background:#2196F3;
    background-color:#2196F3;
    color: white;
}

.champ-datatable-row {
    height: 10px;
    align-content: left;
    margin: .1rem;
    padding: .1rem;
    font-size: 12px;
    /* line-height: .9; */
}

.champ-datatable-date {
    height: 5px;
    color:blue;
    justify-content: right;
}

.champ-field-question { 
    margin-left: .5rem;
    color:royalblue
}

.champ-field-required {
   /* border: 1px solid black; */
    color: red;
    padding-left: 0px;
    padding-right: 3px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    vertical-align: middle; /* middle */
}

.champ-field-expires {
    color:black;
    font-size: x-small;
    margin-left: 5px;
    color: gray
}

.champ-field-expired {
    color:black;
    font-size: x-small;
    margin-left: 5px;
    color: red
}

.champ_history_button {
    min-width: 5rem !important; 
    height: 20px !important;
    float:right !important;
}

.champ_history_button_medical {
    min-width: 5rem !important; 
    height: 20px  !important;  
    float:right  !important; 
    color: white  !important; 
    margin-right: 1rem;
}

.champ-field-checked {
    color: green;
    vertical-align: middle;
}

.champ-field-times {
    color: red;
    vertical-align: middle;
}

.champ-card {
    margin:.5em;
    // padding-top:0px;
    // padding-left:0px;
    // padding-right:0px;
    // padding-bottom:0px;
    background-color: whitesmoke;
}

.champ-card-body {
    background-color:whitesmoke;
    padding: 0rem;
}

.champ-card-bottom {
    position: absolute; 
    bottom: 0px;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   primeng  style changes
///////////////////////////////////////////////////////////////////////////////////////////////////////////////


.p-component {
    font-family: $fontFamily;

}


.p-scrollpanel {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }

    &.custombar1 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: #007ad9 !important; //var(--primary-color)
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: #007ad9 !important;
            }
        }
    }

    &.custombar2 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
            border-bottom: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: var(--layer-2);
            border-radius: 0;
            opacity: 1;
            transition: background-color .2s;
        }
    }
}    

.p-dialog-header-icons {
    background-color: $title-color;
}

.p-field {
    margin-bottom: 2px;
}

.field  {
    //margin: 0rem !important;
    margin: 0rem ;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    //padding: 0px 6.5px 0px 6.5px !important;
    //margin: 3px 0px 2px 0px !important;

    padding: 0px 6.5px 0px 6.5px ;
    margin: 3px 0px 2px 0px;

}

.formgroup-inline {
    //margin: 0px 0px 2px 0px !important;
    margin: 0px 0px 2px 0px;
}

// .col-1{
//     padding: 0px 6.5px 0px, 6.5px  !important;
//     margin: 0px 0px 2px, 0px  !important;
// }

// .col-4{
//     padding: 0px 6.5px 0px, 6.5px  !important;
//     margin: 0px 0px 2px, 0px  !important;
// }




.p-dialog-header {
    background-color: $title-color !important;
    color: white;
    //background: #2196F3;
    width: 100% !important;
    padding: 0rem !important; 
    margin: 0;
}

.p-dialog-title {
    background-color: $title-color;
    color: white;
    //background: #2196F3;
    border-radius: 2px;
    width: 100% !important;
    //margin-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;   
    padding-left: 5px;
    padding-right: 5px;
}

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    //padding: 0rem 0rem .5rem 0rem;
    padding: .5rem .5rem .5rem .5rem;
}

.p-dialog-content {
    width: 100%;
}

.p-dialog-header-close-icon {
    //float: right;
    color: white;
    background-color:  $title-color;
    padding: 0;
}

.p-dialog-header-close {
    background-color:  $title-color;
}


.p-confirmdialog .p-dialog-title {
    background-color: $title-color;
    color: white;
    //background: #2196F3;
    border-radius: 2px;
    width: 100% !important;
    //margin-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;   
    padding-left: 5px;
    padding-right: 5px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 3rem;
    font-weight: bold;
    margin: 1rem;
}


.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}


.p-dialog-mask {
    z-index: 200 !important;  // To bring date pickert to foreground,  DatePicker Control on DynamicDialog 
}

// .bs-daterangepicker-container {  //, .bs-datepicker-container
//     z-index: 5000 !important;
// }

// Solved the problem of the menu not being diabled on dialogs
.p-dialog-mask.p-component-overlay {
    pointer-events: auto;
    z-index: 1000 !important
}

.p-inputtext {
    height:28px;
    font-size: 11px;
    font-weight: bold;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    height: 2.15rem;
}

.p-dropdown {
    height: 28px;
    background-color: white;
}

.p-drop, .ng-multiselect-dropdown {
    background-color: white;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 0px solid #337ab7 !important;
    margin-right: 4px;
    background: whitesmoke !important;
    padding: 0 5px;
    color: #495057 !important;
    border-radius: 2px;
    float: left;
    font-weight: bold;
    max-width: none !important;
}

.dropdown-btn, .multiselect-item-checkbox {
    font-weight: 650;
    font-size: 11px;
    font-family:   $fontFamily;
}

.dropdown-btn span a {
    color: red !important;
}

.p-datatable tr {
    //height: 22px;
    height: 2.5rem;
    line-height: 1;
} 

.p-datatable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-datatable-tbody tr {
    padding: 0rem 0rem !important;
}


.p-tabview {
    background-color: white; 
    //margin-top: .2rem;
    //margin-bottom: .2rem;
}

// .p-tabview-nav {
//     color: linen;
// }


.p-tabview .p-tabview-nav {
    background: white;
    //border: 1px solid #dee2e6;
    //border-width: 0 0 2px 0;
    margin: .2rem;
}


.p-warning {
    color: forestgreen; //lightseagreen
}

.p-checkbox-acknowledgement {
    width: .75rem;
    height: .75rem;
    margin-right: 5px !important;
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.p-button.p-button-icon-only.p-button-rounded {
    height: .5rem;
}

.p-button:hover{
    //background-color: rgb(211, 240, 252);
    //background-color: rgb(239, 244, 247);
    background-color: rgb(215, 236, 248);
    transition: 0.7s;
}

.p-button  {
    i {
        color:blue;
        margin-right: 1rem;
    }
    span {
        font-weight: bold;
        //margin-left: 1rem;
    }
}


// .p-button span {
    
// }


// .p-button-label {
//     align-self: baseline;
// }

// .p-button.p-highlight:hover {
//     background: #re;
//    // border-color: #20aff7;
//    // color: #ffffff;
//   }


// fixes issue in _form-control.scss that remove arrows from basic drop down
.form-control {
    appearance: auto;    
}

.p-accordion {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: 0rem;
    width: 100%;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    color:yellow !important;
}

.p-accordion-header-link {
    background-color: steelblue !important;
    color:ghostwhite !important;
    font-weight: 450 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: .25rem;
}

.p-accordion-content {
    /* width: 100%; */
    /* height: 85px; */
    background-color:whitesmoke;
}


.ui-toast-detail {
    white-space: pre-line;
 }

 .layout-wrapper.layout-menu-horizontal .layout-content {
    padding-top: 125px;
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   other 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

div.multiselect-dropdown {
    background-color: whitesmoke;
    // height:50px;
    font-size: 11px;
    font-weight: bold;    
}

.dropdown-btn {
    background-color: white;
}

.ng-invalid.ng-touched.ng-dirty {
    border-color: red;
}

.bs-datepicker-head {
    background-color: #337ab7 !important;
}

.bs-datepicker-body table td span.selected {
    background: $title-color !important;
}

.p-radiobutton-icon {
    background: $title-color !important;
}

.p-radiobutton-box, .p-radiobutton-icon {
    height: 15px !important;
    width: 15px !important;
    align-items: center;
}

.p-radiobutton {
    margin-top: .25rem !important;
}

.p-checkbox-box {
    height: 15px !important;
    width: 15px !important;
    align-items: center;    
}

.p-checkbox-icon {
    font-size: small !important;
}

.disableDiv {
    pointer-events:none;
    opacity: 0.7; //0.4
}

.true-icon {
    color: green
}

.false-icon {
    color: red;
}

.p-sortable-column-icon {
    font-size: .70rem;
    font-weight: 600;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   PDF 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////


//Needed for ngx-extended-pdf-viewer 
@media print {

    body * {
        visibility:visible;
      }

    #printContainer > .printedPage {
      width: 99%;
    }
  }



// .bs-datepicker-container { 
//     z-index: 9 !important; 
// }

// This is the style that causes the problem with the dialog  Comment out  DL 2021-04-09
//.p-component{
//    height: 28px;
//} 

/*  .p-accordion-content{
    background-color:whitesmoke;
} */


/* .p-accordion, .p-accordion-header, p-accordion-header-text, .p-accordion-header:not(.p-disabled).p-highlight, .p-accordion-header-link{
    background:#2196F3;
    background-color:#2196F3;
    color: white;
} */
/* :host >>> .p-accordion-header-link{
    height: 28px;
    background:#2196F3;
    background-color:#2196F3;
    color: white;    
} */



/* .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #2196F3;
    color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: aliceblue;
} */



// .p-datatable td {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// .p-datatable tr {
//     height: 10px;
//     line-height: .5;
// }

// .p-datatable tr th{
//     margin: 25px;
//     align-content: left;
// }

.true-icon {
    color: green
}

.false-icon {
    color: red;
}

.pointer-hyperlink:hover {
    color:blue;
    text-decoration: underline;
    cursor: pointer;
}

.bs-timepicker-field {
    margin-top: .125rem;
    width: 40px !important;
    height: 28px !important;
    padding: 0.25rem 0.25rem !important;
    //color: rgba(0, 0, 0, 0.75);
    color: #495057;
    font-weight: 799 !important;
    font-size: 11px !important;
    //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}